import { createRouter, createWebHistory } from '@ionic/vue-router';
import MenuView from '../views/MenuView.vue'

const routes = [
  {
    path: '/',
    component: MenuView,
    children: [
      {
        path: '',
        redirect: 'home'
      },
      {
        path: 'home',
        component: () => import('@/views/HomeView.vue')
      },
      {
        path: 'reservar/:type',
        name: 'reservar',
        component: () => import('@/views/ReservasView.vue')
      },
      {
        path: 'misreservas',
        component: () => import('@/views/MisReservasView.vue')
      }
    ]
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
